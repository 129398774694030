import '@fontsource/space-grotesk/700.css'
import '@fontsource/space-grotesk/300.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/900.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/epilogue/600.css'

export const breakPoints = {
  xs: '320px', // Extra Small
  sm: '576px', // Small
  md: '768px', // Medium
  lg: '992px', // Large
  xl: '1200px', // Extra Large
}

export const colors = {
  blue100: '#15b1ff',
  blue200: '#38aef8',

  darkBlue400: '#004876',
  darkBlue500: '#13294b',
  darkBlue600: '#002b52',
  darkBlue900: '#051a29',

  darkGray500: '#3d4c58',

  teal500: '#5cfad3',

  orange200: '#f67b63',
  orange300: '#ff735c',
  orange400: '#e74e3e',
  orange500: '#e74f3d',

  white100: '#f6f6f6',
}

export const theme = {
  breakPoints,
  colors,
}
